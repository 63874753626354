import styles from './AppHeader.module.css'

export default function AppHeader() {
  return (
    <p aria-hidden="true" className={styles.header}>
      <span>Category</span>
      <span>Soundtrack</span>
      <span>Competitor</span>
      <span>Show on main page</span>
      <span>Visible for</span>
    </p>
  )
}
