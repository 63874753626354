import {
  connect,
  RenderFieldExtensionCtx,
  RenderManualFieldExtensionConfigScreenCtx
} from 'datocms-plugin-sdk'
import { render } from './utils/render'
import ConfigScreen from './entrypoints/ConfigScreen'
import FieldExtension from './entrypoints/FieldExtension'
import FieldExtensionConfigScreen from './entrypoints/FieldExtensionConfigScreen'
import 'datocms-react-ui/styles.css'
import './styles/index.css'
import UpdateCompetitorTable from './functions/UpdateCompetitorTable'

const fieldSettings = {
  id: 'soundtrack-competitor-json',
  name: 'Soundtrack Competitor JSON',
}

connect({
  async onBeforeItemUpsert(createOrUpdateItemPayload, ctx) {
    const item: any = createOrUpdateItemPayload.data
    // Get the ID of the Categories Table model
    const CompetitorTableItemTypeId = Object.values(ctx.itemTypes).find(itemType => itemType?.attributes.api_key === 'competitor_categories_table')?.id

    if (item.relationships.item_type.data.id !== CompetitorTableItemTypeId) {
      return true
    }

    try {
      await UpdateCompetitorTable(ctx, item)
      return true
    } catch (err: any) {
      ctx.alert(err.toString())
      return false
    }
  },
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />)
  },
  manualFieldExtensions() {
    return [
      {
        id: fieldSettings.id,
        name: fieldSettings.name,
        type: 'editor',
        fieldTypes: ['json'],
        configurable: true,
      },
    ]
  },
  renderManualFieldExtensionConfigScreen(
    _,
    ctx: RenderManualFieldExtensionConfigScreenCtx,
  ) {
    return render(<FieldExtensionConfigScreen ctx={ctx} />)
  },
  renderFieldExtension(_, ctx: RenderFieldExtensionCtx) {
    return render(<FieldExtension ctx={ctx} />)
  },
})
