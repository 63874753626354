import { Form } from 'datocms-react-ui'
import CompetitorItem from './CompetitorItem'
import { CompetitorItemProps } from '../../entrypoints/FieldExtension'

type Props = {
  fields: CompetitorItemProps[]
  onUpdateField: (id: number, key: string, value: string | boolean | Array<string>) => void
}

export default function CompetitorList({
  fields,
  onUpdateField
}: Props) {

  return (
    <>
      <Form>
        <ul>
          {fields.map((field) => (
            <CompetitorItem
              key={field.id}
              id={field.id}
              category={field.category}
              soundtrack={field.soundtrack}
              competitor={field.competitor}
              collapsedView={field.collapsedView}
              market={field.market}
              onUpdateField={onUpdateField}
            />
          )
          )}
        </ul>
      </Form>
    </>
  )
}
