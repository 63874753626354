import { useEffect, useState } from 'react'
import get from 'lodash/get'
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk'
import { Canvas } from 'datocms-react-ui'
import AppHeader from '../components/AppHeader/AppHeader'
import CompetitorList from '../components/CompetitorList/CompetitorList'

type Props = {
  ctx: RenderFieldExtensionCtx
}

export interface CompetitorItemProps {
  id: number
  category: string
  competitor: string
  soundtrack: string
  collapsedView: boolean
  market: string | Array<string>
}

function toInnerValue(value: string | null, requiredFields: CompetitorItemProps[]) {
  if (value === null) {
    return requiredFields
  }

  const parsedValue = JSON.parse(value)

  return parsedValue
}

export default function FieldExtension({ ctx }: Props) {
  const pluginParameters: any = ctx.parameters
  const locale = ctx.locale
  const requiredFields: CompetitorItemProps[] = JSON.parse(pluginParameters?.requiredFields)[locale]
  const rawValue = get(ctx.formValues, ctx.fieldPath) as string
  const [fields, setFields] = useState<CompetitorItemProps[]>(toInnerValue(rawValue, requiredFields))

  useEffect(() => {

    // Update the fields based on the new requiredFields
    const updatedFields = requiredFields.map(newField => {
      const existingField = toInnerValue(rawValue, requiredFields).find((field: any) => field.id === newField.id)
      if (existingField) {
        return { ...existingField, category: newField.category, soundtrack: newField.soundtrack }
      } else {
        return newField
      }
    })

    setFields(updatedFields)

    if (JSON.stringify(JSON.parse(rawValue)) !== JSON.stringify(updatedFields)) {
      ctx.setFieldValue(ctx.fieldPath, JSON.stringify(updatedFields))
      setFields(updatedFields)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginParameters?.requiredFields])


  const handleUpdateField = (id: number, key: string, value: string | boolean | Array<string>) => {
    const updatedJSON = toInnerValue(rawValue, requiredFields).map((item: any) => (
      item.id === id ? { ...item, [key]: value } : item
    ))
    ctx.setFieldValue(ctx.fieldPath, JSON.stringify(updatedJSON))
  }

  return (
    <Canvas ctx={ctx}>
      <AppHeader />
      <CompetitorList
        fields={fields}
        onUpdateField={handleUpdateField}
      />
    </Canvas>
  )
}
