import { useState } from 'react'
import { TextField, FieldGroup, SwitchField, Button, Dropdown, CaretUpIcon, CaretDownIcon, DropdownMenu, DropdownOption } from 'datocms-react-ui'
import styles from './Competitor.module.css'

type Props = {
  id: number
  category: string
  soundtrack: string
  competitor: string
  collapsedView: boolean
  market: string | Array<string>
  onUpdateField: (id: number, key: string, value: string | boolean | Array<string>) => void
}

export default function CompetitorItem({
  id,
  category,
  soundtrack,
  competitor,
  collapsedView,
  market,
  onUpdateField
}: Props) {
  const [inputValue, setInputValue] = useState(competitor)
  const [showCollapsed, setShowCollapsed] = useState(collapsedView)
  const [visibleFor, setVisibleFor] = useState(market)

  function handleValueChange(input: string) {
    onUpdateField(id, 'competitor', input)
    setInputValue(input)
  }

  function handleSwitchChange(value: boolean) {
    onUpdateField(id, 'collapsedView', value)
    setShowCollapsed(value)
  }

  function handleDropdownChange(value: string | Array<string>) {
    onUpdateField(id, 'market', value)
    setVisibleFor(value)
  }

  return (
    <li className={styles.listItem}>
      <FieldGroup className={styles.fieldGroup}>
        <TextField
          required
          name={`${id}-category`}
          id={`${id}-category`}
          label=""
          value={category}
          onChange={() => null}
          formLabelProps={{
            children: <></>,
            htmlFor: `${id}-value`,
            className: 'row-only',
          }}
          textInputProps={{
            disabled: true,
          }}
        />
      </FieldGroup>
      <FieldGroup className={styles.fieldGroup}>
        <TextField
          required
          name={`${id}-soundtrack`}
          id={`${id}-soundtrack`}
          label=""
          value={soundtrack}
          onChange={() => null}
          formLabelProps={{
            children: <></>,
            htmlFor: `${id}-value`,
            className: 'row-only',
          }}
          textInputProps={{
            disabled: true,
          }}
        />
      </FieldGroup>
      <FieldGroup className={styles.fieldGroup}>
        <TextField
          required={false}
          name={`${id}-competitor`}
          id={`${id}-competitor`}
          label=""
          value={inputValue}
          onChange={handleValueChange}
          formLabelProps={{
            children: <></>,
            htmlFor: `${id}-value`,
            className: 'row-only',
          }}
        />
      </FieldGroup>
      <FieldGroup className={styles.fieldGroup}>
        <SwitchField
          name={`${id}-collapsed`}
          id={`${id}-collapsed`}
          label=""
          value={showCollapsed}
          onChange={handleSwitchChange}
        />
      </FieldGroup>
      <FieldGroup className={styles.fieldGroup}>
        <Dropdown
          renderTrigger={({ open, onClick }) => (
            <Button
              onClick={onClick}
              rightIcon={open ? <CaretUpIcon /> : <CaretDownIcon />}
            >
              {visibleFor}
            </Button>
          )}
        >
          <DropdownMenu>
            <DropdownOption onClick={() => handleDropdownChange('ALL')} children="ALL" />
            <DropdownOption onClick={() => handleDropdownChange(['US', 'CA'])} children="US & CA" />
            <DropdownOption onClick={() => handleDropdownChange('US')} children="US" />
            <DropdownOption onClick={() => handleDropdownChange('CA')} children="CA" />
            <DropdownOption onClick={() => handleDropdownChange('ROW')} children="ROW" />
            <DropdownOption onClick={() => handleDropdownChange('NONE')} children="NONE" />
          </DropdownMenu>
        </Dropdown>
      </FieldGroup>
    </li>
  )
}
