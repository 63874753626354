import { useState } from 'react'
import { RenderManualFieldExtensionConfigScreenCtx } from 'datocms-plugin-sdk'
import { Canvas, Form, TextareaField } from 'datocms-react-ui'

type Props = {
  ctx: RenderManualFieldExtensionConfigScreenCtx
}

export default function FieldExtensionConfigScreen({ ctx }: Props) {
  const pluginParameters = ctx.parameters
  const [requiredItemsValue, setRequiredItemsValue] = useState<string>(
    pluginParameters?.requiredFields
      ? String(pluginParameters?.requiredFields)
      : '',
  )

  function handleRequiredItemsChange(newValue: string) {
    setRequiredItemsValue(newValue)
    ctx.setParameters({ ...pluginParameters, requiredFields: newValue })
  }

  return (
    <Canvas ctx={ctx}>
      <Form>
        <TextareaField
          id="requiredFields"
          name="requiredFields"
          label="Required fields"
          hint="DO NOT CHANGE! THIS FIELD IS AUTOMATED"
          value={requiredItemsValue}
          onChange={handleRequiredItemsChange}
        />
      </Form>
    </Canvas>
  )
}
