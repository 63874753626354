import { OnBootCtx } from 'datocms-plugin-sdk'
import isEmpty from 'lodash/isEmpty'

type CompetitorCategory = {
  id: number
  category: string
  soundtrack: string
  competitor: string
  collapsedView: boolean
  market: string
}

export type CompetitorCategoriesProps = {
  [locale: string]: CompetitorCategory[]
}

export default async function UpdateCompetitorTable(ctx: OnBootCtx, item: any) {
  const fields = await ctx.loadFieldsUsingPlugin()
  const categories = item.attributes.categories

  const localeCategories = Object.entries(categories).reduce((acc, [locale, categoriesString] ) => {

    // Locale with missing categories use 'en' as fallback.
    const localContent = !isEmpty(JSON.parse(categoriesString as string)) ? categoriesString : categories['en']

    acc[locale] = Object.entries(JSON.parse(localContent)).map(([key, value], index) => ({
      id: index + 1,
      category: key,
      soundtrack: String(value),
      competitor: "",
      collapsedView: true,
      market: "ALL"
    }))
    return acc
  }, {} as CompetitorCategoriesProps)


  for (const field of fields) {
    const { appearance } = field.attributes
    const operations: any = []
    if (appearance.editor === ctx.plugin.id) {
      operations.push({
        operation: 'updateEditor',
        newParameters: {
          ...appearance.parameters,
          requiredFields: JSON.stringify(localeCategories),
        },
      })
      ctx.updateFieldAppearance(field.id, operations)
    }
  }
}